<template>
<div>
  <v-icon v-text="`mdi-alpha-p`"/>
  <v-icon v-text="`mdi-alpha-l`"/>
  <v-icon v-text="`mdi-alpha-u`"/>
  <v-icon v-text="`mdi-alpha-m`"/>
  <v-icon v-text="`mdi-alpha-b`"/>
  <v-icon v-text="`mdi-alpha-u`"/>
  <v-icon v-text="`mdi-alpha-s`"/>
</div>
</template>

<script>
export default {
  namespaced: true,
}
</script>

<style scoped>

</style>