var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-5",staticStyle:{"width":"100%"},attrs:{"raised":"","rounded":"","elevation":"24"}},[_c('v-toolbar',{attrs:{"rounded":"","dense":""}},[_c('v-icon',{staticClass:"mr-3",domProps:{"textContent":_vm._s("mdi-account-tie")}}),_c('v-toolbar-title',[_c('span',{domProps:{"textContent":_vm._s("Campaigns")}})]),_c('v-spacer'),_c('FilterField',{ref:"filter",staticClass:"d-flex flex-grow-0 flex-shrink-1",attrs:{"disabled":_vm.busy},on:{"change":function($event){_vm.filter = $event}}}),_c('v-divider',{staticClass:"ml-5 mr-2",attrs:{"vertical":"","inset":""}}),_c('v-switch',{attrs:{"color":"primary","label":_vm.statusSwitchLabel(),"hide-details":""},model:{value:(_vm.activeOnly),callback:function ($$v) {_vm.activeOnly=$$v},expression:"activeOnly"}}),_c('ViewColumnMenu',{attrs:{"columns":_vm.columns}}),_c('TooltipButton',{attrs:{"icon":"mdi-refresh","tooltip":"Refresh Campaigns"},on:{"click":_vm.refreshItems}}),(_vm.fullscreen)?_c('TooltipButton',{attrs:{"icon":"mdi-fullscreen","tooltip":"Fullscreen"},on:{"click":function($event){return _vm.$emit('open')}}}):_c('TooltipButton',{attrs:{"left":"","icon":"mdi-close","tooltip":"Close Fullscreen"},on:{"click":function($event){return _vm.$emit('close')}}})],1),_c('v-data-table',{attrs:{"loading":_vm.busy,"dense":"","sort-desc":"","must-sort":"","hide-default-footer":"","sort-by":"roi","no-data-text":"No Campaigns found for this Account","no-results-text":"No Campaigns found for this Account","items-per-page":-1,"headers":_vm.headers,"items":_vm.computedItems},scopedSlots:_vm._u([{key:"item.daily_budget",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted.daily_budget)+" ")]}},{key:"item.budget_remaining",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted.budget_remaining)+" ")]}},{key:"item.clicks",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted.clicks)+" ")]}},{key:"item.impressions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted.impressions)+" ")]}},{key:"item.ctr",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted.ctr)+" ")]}},{key:"item.cpc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted.cpc)+" ")]}},{key:"item.cpm",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted.cpm)+" ")]}},{key:"item.cpp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted.cpp)+" ")]}},{key:"item.spend",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted.spend)+" ")]}},{key:"item.revenue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted.revenue)+" ")]}},{key:"item.profit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.formatted.profit)+" ")]}},{key:"item.roi",fn:function(ref){
var item = ref.item;
return [_c('Chip',{attrs:{"small":"","float":item.roi,"text":item.formatted.roi}})]}},{key:"item.updated_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.updated_time).format("MM/DD/YY"))+" ")]}},{key:"item.created_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_time).format("MM/DD/YY"))+" ")]}},{key:"item.refreshed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.refreshed).utc().format("MM/DD/YY HH:mm:ss z"))+" ")]}},{key:"item.data-table-expand",fn:function(ref){
var isSelected = ref.isSelected;
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex flex-row align-center"},[_c('StatusButton',{attrs:{"item":item}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }