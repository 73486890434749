var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"raised":"","rounded":"","elevation":"24","loading":_vm.busy,"disabled":_vm.busy}},[_c('v-toolbar',{staticStyle:{"background-color":"gray"},attrs:{"dense":""}},[_c('span',{staticClass:"text-h6",domProps:{"textContent":_vm._s("Scope")}}),_c('v-spacer'),_c('TooltipButton',{attrs:{"icon":("mdi-chevron-" + (_vm.show ? 'up' : 'down')),"tooltip":((_vm.show ? 'Collapse' : 'Expand') + " Scope")},on:{"click":function($event){_vm.show = !_vm.show}}})],1),_c('v-expand-transition',[(_vm.show)?_c('div',[_c('div',{staticClass:"d-flex flex flex-row align-center justify-space-around px-4 mb-1"},[_c('div',{staticClass:"d-flex flex"},[_c('FilterField',{ref:"filter",attrs:{"disabled":_vm.busy},on:{"change":function($event){_vm.filter = $event}}})],1)]),_c('v-card',{staticClass:"mx-auto overflow-y-auto",attrs:{"max-height":"400"}},[_c('v-card-text',{staticClass:"mx-auto"},[_c('v-treeview',{attrs:{"dense":"","selectable":"","open-on-click":"","selected-color":"primary","items":_vm.computedItems,"item-key":"id"},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
var leaf = ref.leaf;
var selected = ref.selected;
var indeterminate = ref.indeterminate;
var active = ref.active;
var open = ref.open;
return [_vm._v(" "+_vm._s(_vm.wat(item, leaf, selected, indeterminate, active, open) ? item.name : item.name)+" ")]}}],null,false,2527171002),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }