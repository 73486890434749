var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"raised":"","rounded":"","elevation":"24","disabled":_vm.busy}},[_c('v-toolbar',{attrs:{"rounded":""}},[_c('v-icon',{staticClass:"mr-3",domProps:{"textContent":_vm._s(_vm.card.icon)}}),_c('v-toolbar-title',[_c('span',{domProps:{"textContent":_vm._s(_vm.card.name)}}),_c('span',{staticClass:"subtitle-2 font-weight-light ml-5 hidden-sm-and-down",domProps:{"textContent":_vm._s(_vm.card.subtitle)}})]),_c('v-spacer'),_c('FilterField',{ref:"filter",staticClass:"d-flex flex-grow-0 flex-shrink-1",attrs:{"disabled":_vm.busy},on:{"change":function($event){_vm.filter = $event}}}),_c('v-divider',{staticClass:"ml-5 mr-2",attrs:{"vertical":"","inset":""}}),_c('v-switch',{attrs:{"disabled":_vm.busy,"color":"primary","label":_vm.statusSwitchLabel(),"hide-details":""},model:{value:(_vm.activeOnly),callback:function ($$v) {_vm.activeOnly=$$v},expression:"activeOnly"}}),_c('ViewColumnMenu',{attrs:{"columns":_vm.columns}}),_c('TooltipButton',{attrs:{"icon":"mdi-refresh","tooltip":"Refresh Accounts"},on:{"click":_vm.refreshItems}}),_c('ExpandButton',{attrs:{"domain":_vm.card.name,"expand":function () {_vm.card.expanded = !_vm.card.expanded},"is-expanded":_vm.card.expanded}}),_c('TooltipButton',{attrs:{"icon":"mdi-close","tooltip":"Close"},on:{"click":function($event){_vm.card.visible = false}}})],1),_c('v-expand-transition',[(_vm.card.expanded)?_c('v-data-table',{attrs:{"dense":"","sort-desc":"","show-expand":"","single-expand":"","sort-by":"performance.roi","item-key":"id","hide-default-footer":"","loading":_vm.busy,"items":_vm.computedItems,"items-per-page":-1,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.performance.spend",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.performance.spend_str)+" ")]}},{key:"item.performance.revenue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.performance.revenue_str)+" ")]}},{key:"item.performance.profit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.performance.profit_str)+" ")]}},{key:"item.performance.roi",fn:function(ref){
var item = ref.item;
return [_c('Chip',{attrs:{"small":"","float":item.performance.roi,"text":item.performance.roi_str}})]}},{key:"item.performance.active",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.performance.active_str)+" ")]}},{key:"item.performance.inactive",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.performance.inactive_str)+" ")]}},{key:"item.created_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.created_time).format("MM/DD/YY"))+" ")]}},{key:"item.data-table-expand",fn:function(ref){
var isSelected = ref.isSelected;
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('div',{staticClass:"d-flex flex flex-row align-center"},[_c('StatusButton',{attrs:{"item":item}}),_c('ExpandButton',{attrs:{"small":"","domain":"Account","expand":expand,"is-expanded":isExpanded,"disabled":item.performance.active + item.performance.inactive < 1}})],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"d-flex flex flex-row align-start ma-2"},[_c('CampaignTable',{attrs:{"fullscreen":true,"accountID":item.account_id},on:{"open":function($event){return _vm.$emit('open', item.account_id)},"close":function($event){return _vm.$emit('close')}}})],1)])]}}],null,false,2298978557)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }